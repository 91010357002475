import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../images/logo.png'
import Slider from "react-slick";
import Helmet from "react-helmet";
import favicon from "../images/favicon.png"

const sliderData = [
    {
        img: "home1",
        title: "Enterprise Solutions Group",
        classes: "p-center text-center",
    },
    {
        img: "home2",
        title: "Cyber Health Check",
        desc: "Customized audit and compliance software developed by auditors for auditors. With our automated solution you can significantly reduce your audit time.",
        classes: "p-center text-center",
        link: '/#cyber-health-check'
    },
    {
        img: "home3",
        title: "Consulting Services",
        desc: "Our team has decades of combined experience across industries. We have a proven track record of providing meaningful and effective guidance to solve whatever technical challenges our clients encounter.",
        classes: "p-center text-center",
        link: "/#virtual-chief-info"
    },
    {
        img: "home4",
        title: "Configuration baseline review",
        desc: "Analyze and configure your Enterprise Architecture for maximum efficiency and throughput.",
        classes: "p-center text-center",
        link: "/#enterprice-security-review"
    },
    {
        img: "home5",
        title: "Compliance Audits and Assessments",
        classes: "p-center text-center",
    },
    {
        img: "home6",
        title: "Penetration Testing",
        desc: "Vulnerability and Pen Testing - Identify all exploitable weaknesses in your organization’s cyber-infrastructure.",
        classes: "p-center text-center",
        link: '/#penetration-testing'
    },
    {
        img: "home7",
        title: "Policy Development",
        desc: "No matter what your regulatory requirements, allow us to build a solid governance and compliance foundation for your organization.",
        classes: "p-center text-center",
        link: "/#policy-development"
    },
];
const isBrowser = () => typeof window !== "undefined"
const path = isBrowser() && window.location.pathname;

const Header = ({
    pageTitle
}) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
    };
    return (
        <header>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" type="image/x-icon" href={favicon ? favicon : ""} />
                <title>{pageTitle}</title>
            </Helmet>
            <section className="topbar border-bottom">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li><a href="mailto:custsvs@esgsecurity.org"><i className="fa fa-envelope"></i> custsvs@esgsecurity.org</a></li>
                                <li><a href="(866) 579-5111"><i className="fa fa-phone"></i> (866) 579-5111</a></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="header-section py-2">
                <Container>
                    <Row>
                        <Col md="4" lg="3" xl="2" className="col-6">
                            <Row>
                                <Col md="5" className="text-right col-5">
                                    <img src={logo} alt="ESG Logo" className="img-fluid" />
                                </Col>
                                <Col md="7" className="text-center text-white py-md-3 py-lg-3 py-xl-3 ml-xl-n1 ml-md-n4 col-7 py-2">
                                    <h5>Enterprise Solutions Group</h5>
                                    <h6 className="m-0">A History of Service and Commitment to Excellence</h6>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="5" lg="7" xl="8" className="col-2">
                            <nav className="navbar navbar-expand-lg navbar-light px-xl-5 py-lg-3">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul className="navbar-nav">
                                        <li className={`nav-item ${path === '' || path === '/' || path && path.search('#') !== -1 ? 'active' : ''}`}>
                                            <a className="nav-link" href="/">HOME</a>
                                        </li>

                                        <li className={`nav-item dropdown ${path && path.search('penetration-and-vulnerability-testing') !== -1 || path && path.search('consulting-services') !== -1 ? 'active' : ''}`}>
                                            <a className="nav-link dropdown-toggle" href="#" id="servicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                SERVICES
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="servicesDropdown">
                                                <a className="dropdown-item" href="/penetration-and-vulnerability-testing">Penetration And Vulnerability Testing</a>
                                                <a className="dropdown-item" href="/consulting-services">Consulting Services</a>
                                            </div>
                                        </li>
                                        <li className={`nav-item dropdown ${path && path.search('cyber-health-check-chc') !== -1 || path && path.search('policy-development') !== -1 ? 'active' : ''}`}>
                                            <a className="nav-link dropdown-toggle" href="#" id="productsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                PRODUCTS
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="productsDropdown">
                                                <a className="dropdown-item" href="/cyber-health-check-chc">Cyber Health Check (CHC)</a>
                                                <a className="dropdown-item" href="/policy-development">Governance Policy Development</a>
                                            </div>
                                        </li>
                                        <li className={`nav-item ${path && path.search('about-us') !== -1 ? 'active' : ''}`}>
                                            <a className="nav-link" href="/about-us">ABOUT US</a>
                                        </li>
                                        <li className={`nav-item ${path && path.search('contact-us') !== -1 ? 'active' : ''}`}>
                                            <a className="nav-link" href="/contact-us">CONTACT US</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </Col>
                        <Col md="3" lg="2" className="text-md-center col-3 py-lg-2">
                            <a href="" className="btn btn-primary btn-theme my-2 px-md-3 py-md-2 p-0">Get a Quote</a>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Slider {...settings} className="home-slider">
                    {sliderData.map((data) => {
                        return (
                            <div className={`home ${data.img} ${data.classes}`}>
                                <div>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <div className="slider-contain">
                                                    <div>
                                                        <h1>{data.title}</h1>
                                                        {data.desc ? (
                                                            <a href={data.link ? data.link : ''}><h4 className="mx-md-5 px-md-5">{data.desc}</h4></a>
                                                        ) : ''}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </section>
        </header>
    )
}

export default Header;
